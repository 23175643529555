import { useEffect, useState } from 'react';
import { getPublicEnv } from '../services/env/env';

export function useHomePageUrl() {
    const [homePageUrl, setHomePageUrl] = useState<string>('/configurator');

    useEffect(() => {
        getPublicEnv('FEAT_FLAG_REDIRECT').then((env) => {
            const isRedirectOn = env === '1';

            setHomePageUrl(isRedirectOn ? 'https://useformify.com/' : '/');
        });
    }, []);

    return homePageUrl;
}
