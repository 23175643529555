import React, { FC, useState, CSSProperties, useContext } from 'react';
import {
    HeaderIconStyled,
    HeaderLogoStyled,
    HeaderWrapperStyled,
    ToggleVisibilityWrapperStyled,
} from './Header.styled';
import { LogoIcon } from '../icons/Logo/Logo';
import { MenuIcon } from '../icons/Menu/Menu';
import { ChevronUpIcon } from '../icons/ChevronUp/ChevronUp';
import { ProductCountBasket } from './ProductCountBasket/ProductCountBasket';
import Link from 'next/link';
import { ConfiguratorQueryParams, RouteName } from '../../constants/router';
import { Menu } from '../Menu/Menu';
import { useRouterParams } from '../../hooks/useRouterParams';
import { useBreakpoints } from '../../hooks/useBreakpoints';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import { useSelector } from 'react-redux';
import { getCartItemsCount } from '../../store/project/selectors';
import { getPublicEnv } from '../../services/env/env';
import { useHomePageUrl } from '../../hooks/useHomePageUrl';
import { cartContext } from '../CartSidePanel/CartSidePanelProvider';
import { Lang } from '../Lang/Lang';
import { CurrencyHeader } from '../Currency/CurrencyHeader';
import { useEnv } from '../../hooks/useEnv';

const Auth = dynamic(
    async () => {
        const accountIsActive = (await getPublicEnv('FEAT_FLAG_ACCOUNT')) === '1';

        if (accountIsActive) {
            return import('../Auth/Auth');
        }

        return import('../Auth/AuthOldWay');
    },
    {
        ssr: false,
        loading: () => <HeaderIconStyled />,
    },
);

interface HeaderProps {
    isVisible: boolean;
    transparent?: boolean;
    handleVisbilityChanged?: () => void;
    position?: CSSProperties['position'];
}

export const Header: FC<HeaderProps> = ({ isVisible, handleVisbilityChanged, transparent, position }) => {
    const [showMenu, setMenuVisibility] = useState(false);
    const { pathname } = useRouter();
    const { params } = useRouterParams<ConfiguratorQueryParams>();
    const { isMobile } = useBreakpoints();
    const shouldHeaderBeFixed = params.option === 'summary' && isMobile;
    const cartItemsCount = useSelector(getCartItemsCount);
    const currentRouteIsConfigurator = pathname === RouteName.Configurator;
    const showCart = !currentRouteIsConfigurator || (currentRouteIsConfigurator && cartItemsCount === 0);
    const homepage = useHomePageUrl();
    const { openCart } = useContext(cartContext);
    const FEAT_FLAG_MENU = useEnv('FEAT_FLAG_MENU');

    return (
        <>
            <HeaderWrapperStyled isVisible={isVisible} transparent={transparent} position={position} data-test="header">
                <div>
                    {FEAT_FLAG_MENU === '1' && (
                        <HeaderIconStyled data-test-id="hamburger_menu_icon" onClick={() => setMenuVisibility(true)}>
                            <MenuIcon />
                        </HeaderIconStyled>
                    )}
                    {handleVisbilityChanged && !shouldHeaderBeFixed && (
                        <ToggleVisibilityWrapperStyled
                            onClick={(e) => {
                                e.stopPropagation();
                                handleVisbilityChanged();
                            }}
                            isVisible={isVisible}
                        >
                            <div className="pointer-events-none">
                                <ChevronUpIcon />
                            </div>
                        </ToggleVisibilityWrapperStyled>
                    )}
                </div>
                <Link href={homepage} passHref prefetch={false} legacyBehavior>
                    <HeaderLogoStyled as="a">
                        <LogoIcon />
                    </HeaderLogoStyled>
                </Link>
                <div className="flex justify-end">
                    <div>
                        <Lang transparent={transparent} />
                    </div>
                    <div>
                        <CurrencyHeader transparent={transparent} />
                    </div>
                    <div>
                        <Auth />
                    </div>
                    <div>
                        {showCart && (
                            <span
                                onClick={() => {
                                    openCart();
                                }}
                            >
                                <HeaderIconStyled as="a">
                                    <ProductCountBasket />
                                </HeaderIconStyled>
                            </span>
                        )}
                    </div>
                </div>
            </HeaderWrapperStyled>

            <Menu isOpen={showMenu} onClose={() => setMenuVisibility(false)} />
        </>
    );
};
